import React from "react";
import styled from "styled-components"
import { Box } from "@motorata/base-ui.components.atoms._atoms";
import ToolDeleteOffer from "./ToolDeleteOffer";
import ToolPasswordReset from "./ToolPasswordReset";

const Tools = () => {
    return (
        <Wrapper>
            <Content>
                <ToolPasswordReset />
                {/*<ToolSendEmail />*/}
            </Content>
            <Content>
                <ToolDeleteOffer />
            </Content>
        </Wrapper>
    )
}

export default Tools

const Content = styled(Box)`
  display: flex;
  flex-direction: column;
  grid-gap: ${({ theme }) => theme.space.M};
  `;

const Wrapper = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${({ theme }) => theme.space.M};
`;
