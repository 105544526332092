import React, { useState } from "react"
import styled from "styled-components"
import { Box, Row } from "@motorata/base-ui.components.atoms._atoms"
import { ProfileType } from "@motorata/base-ui.types.api"
import Spinner from "@motorata/base-ui.components.atoms.spinner"
import { Pagination } from "@motorata/base-ui.components.molecules.pagination";
import ProfileBlock from "components/shared/ProfileBlock";

const ProfilesList = ({ loading, data, page, totalPages, onChangePage }: {
  loading: boolean
  page: number
  totalPages: number
  onChangePage: (e: number) => void
  data: ProfileType[]
}) => {
  const [edit, setEdit] = useState<ProfileType | boolean>(false)

  if (loading)
    return (
      <Wrapper>
        <Row justifyContent="center">
          <Spinner size="30px" />
        </Row>
      </Wrapper>
    )

  return (
    <Wrapper>
      {data.map(el => (
        <ProfileBlock key={el.id} data={el} />
      ))}
      <Pagination
          defaultPage={page + 1}
          totalPages={totalPages}
          onChange={(e) => onChangePage(e - 1)}
      />
    </Wrapper>
  )
}

export default ProfilesList

const Wrapper = styled(Box)`
  padding: 0;
  display: flex;
  flex-direction: column;
  grid-gap: ${({ theme }) => theme.space.XS};
`
