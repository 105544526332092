import React from "react"
import "./App.css"
import { StyledComponentsProvider } from "@motorata/base-ui.providers.styled-components"
import { Provider } from "react-redux"
import { store } from "store/store"
import Home from "./Home"

function App() {
  return (
    <Provider store={store}>
      <StyledComponentsProvider theme="motorata">
        <Home />
      </StyledComponentsProvider>
    </Provider>
  )
}

export default App
