import React, { useCallback } from "react"
import Modal from "@motorata/base-ui.components.molecules.modal"
import Input from "@motorata/base-ui.components.atoms.input"
import Button from "@motorata/base-ui.components.atoms.button"
import {
  LocalStorageService,
  ProfileService,
  PublicService,
} from "@motorata/base-ui.services.api-service"
import { useForm } from "react-hook-form"
import { EMAIL_REGEX } from "@motorata/base-ui.utils.regex"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import Alert from "@motorata/base-ui.components.molecules.alert";
import { errorLogin, initLogin, successLogin } from "store/auth"
import { RootState } from "store/store"

const Login = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm()

  const { isLoggingIn, isLoginError } = useSelector((state: RootState) => state.auth)

  const onSubmit = useCallback(async (el: any) => {
    dispatch(initLogin())
    try {
      const user = await PublicService.login(el.username, el.password)
      await LocalStorageService.setObject("token", user.token)
      dispatch(successLogin(user))
    } catch (e) {
      console.log("[ERROR PublicService.login]:", e)
      LocalStorageService.removeObject("token")
      LocalStorageService.removeObject("profileId")
      dispatch(errorLogin())
      return
    }
  }, [])

  return (
    <Modal
      show={true}
      icon="shield"
      title="Panel administracyjny"
      text="Zaloguj się do panelu administracyjnego"
      maxWidth="400px"
    >
      <>
        {
          isLoginError &&
            <Alert type="error" small>
              Nie udało się zalogować. Nie właściwy email lub hasło.
            </Alert>
        }
        <Input
          name="username"
          label="E-mail*"
          error={errors.username}
          rhf={register("username", {
            required: true,
            pattern: {
              value: EMAIL_REGEX,
              message: "Niepoprawny format email",
            },
          })}
          marginBottom="XS"
        />
        <Input
          name="password"
          label="Hasło*"
          type="password"
          error={errors.password}
          rhf={register("password", {
            required: true,
          })}
          marginBottom="S"
        />
        <Button width="100%" marginBottom="XS" loading={isLoggingIn} onClick={handleSubmit(onSubmit)}>
          Zaloguj się
        </Button>
      </>
    </Modal>
  )
}

export default Login
