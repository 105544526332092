import React, { ReactNode } from "react"
import styled from "styled-components"
import { StyledButtonProps, buttonStyle } from "@motorata/base-ui.components.atoms.button"
import Icon from "@motorata/base-ui.components.atoms.icon"
import { Row } from "@motorata/base-ui.components.atoms._atoms"
import { NavLink } from "react-router-dom";

export type LinkProps = StyledButtonProps & {
  children: ReactNode
  type?: "primary" | "secondary" | "third" | "fourth"
  icon?: string
  to: string
}

const Link = ({
  children,
  to,
  type = "primary",
  icon,
  disabled,
  padding = "12px 30px",
  hoverColor,
  hoverBackground,
  ...rest
}: LinkProps) => {
  return (
    <StyledLink
      to={to}
      styledType={type}
      disabled={disabled}
      padding={padding}
      hoverColor={hoverColor}
      hoverBackground={hoverBackground}
      {...rest}
    >
      <Row alignItems="center">
        {icon && <Icon icon={icon} />}
        {children}
      </Row>
    </StyledLink>
  )
}

export default Link

const StyledLink = styled(NavLink)<StyledButtonProps>`
  ${buttonStyle};
  
  &.active {
    background: ${({ theme }) => theme.color.primaryDarker};
  }
`
