import React, { useEffect, useState } from "react"
import { Box } from "@motorata/base-ui.components.atoms._atoms"
import { H1, Label, P } from "@motorata/base-ui.components.atoms.typography"
import { translate } from "@motorata/base-ui.data"
import Tag from "@motorata/base-ui.components.atoms.tag"
import { addSpacesFromPhoneNumber } from "@motorata/base-ui.utils.string"
import { ProfileType } from "@motorata/base-ui.types.api"
import { useNavigate, useParams } from "react-router-dom"
import Modal from "@motorata/base-ui.components.molecules.modal"
import Spinner from "@motorata/base-ui.components.atoms.spinner"
import Status from "components/atoms/Status/Status"
import { ProfileService } from "services/ProfileService"
import styled from "styled-components";

const ProfileModal = () => {
  const navigate = useNavigate()
  const { profileId } = useParams()

  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<null | ProfileType>(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const profile = await ProfileService.getProfile(`${profileId}`)
        setData(profile)
        setLoading(false)
      } catch (e) {
        console.error("[ERROR AdminProfileService.getProfile]:", e)
      }
    }
    fetchData()
  }, [])

  return (
    <Modal
      show={true}
      setShow={() => navigate("/panel/profiles")}
      type="side"
      title="Informacje profilu"
      maxWidth="700px"
    >
      <>
        {loading || !data ? (
          <Box mt="XL">
            <Spinner />
          </Box>
        ) : (
          <Wrapper>
            <Box marginBottom="M">
              <Status label={data.status} />
              <H1 marginTop="XS" marginBottom="0">
                {data.name}
              </H1>
              {data.status === "active" && (
                <Box>
                  <a href={`https://motorata.pl/${data.slug}`}>
                    <Label type="third" color="primary">
                      https://motorata.pl/{data.slug}
                    </Label>
                  </a>
                </Box>
              )}
            </Box>
            <InfoWrapper>
              <InfoContent>
                <Box>
                  <Label type="third">Email</Label>
                  <P margin="0">
                    {data.email}
                  </P>
                </Box>
                <Box>
                  <Label type="third">Numer telefonu</Label>
                  <P margin="0">
                    {addSpacesFromPhoneNumber(data.phone)}
                  </P>
                </Box>
              </InfoContent>
            </InfoWrapper>
            <InfoWrapper>
              <Label display="block" marginBottom="XS">
                Adres
              </Label>
              <InfoContent>
                <Box>
                  <Label type="third">Adres</Label>
                  <P margin="0">
                    {data.address.address}
                  </P>
                </Box>
                <Box>
                  <Label type="third">Miasto</Label>
                  <P margin="0">
                    {data.address.city}
                  </P>
                </Box>
                <Box>
                  <Label type="third">Kod pocztowy</Label>
                  <P margin="0">
                    {data.address.postCode}
                  </P>
                </Box>
                <Box>
                  <Label type="third">Kraj</Label>
                  <P margin="0">
                    Polska
                  </P>
                </Box>
              </InfoContent>
            </InfoWrapper>
            {data.company && (
              <InfoWrapper>
                <Label display="block" marginBottom="XS">
                  Dane firmy
                </Label>
                <InfoContent>
                  <Box>
                    <Label type="third">Nazwa firmy</Label>
                    <P margin="0">
                      {data.company.name}
                    </P>
                  </Box>
                  <Box>
                    <Label type="third">NIP</Label>
                    <P margin="0">
                      {data.company.nip}
                    </P>
                  </Box>
                  <Box>
                    <Label type="third">Adres</Label>
                    <P margin="0">
                      {data.company.address}
                    </P>
                  </Box>
                  <Box>
                    <Label type="third">Miasto</Label>
                    <P margin="0">
                      {data.company.city}
                    </P>
                  </Box>
                  <Box>
                    <Label type="third">Kod pocztowy</Label>
                    <P margin="0">
                      {data.company.postCode}
                    </P>
                  </Box>
                  <Box>
                    <Label type="third">Kraj</Label>
                    <P margin="0">
                      Polska
                    </P>
                  </Box>
                </InfoContent>
              </InfoWrapper>
            )}
            <InfoWrapper>
              <Label display="block" marginBottom="XS">
                Integracje
              </Label>
              <InfoContent>
                <TagsWrapper>
                  {data.integrations.otomoto?.active && (
                      <Tag
                          type="secondary"
                          showBorder={false}
                          background="greenBackground"
                          icon="check"
                          iconColor="green"
                          label="Otomoto"
                      />
                  )}
                </TagsWrapper>
              </InfoContent>
            </InfoWrapper>
            <InfoWrapper>
              <Label display="block" marginBottom="XS">
                Opiekun
              </Label>
              <InfoContent>
                <P margin="0">
                  {data.coordinator}
                </P>
              </InfoContent>
            </InfoWrapper>
          </Wrapper>
        )}
      </>
    </Modal>
  )
}

export default ProfileModal

const InfoContent = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${({ theme }) => theme.space.XS};
`

const InfoWrapper = styled(Box)`
  margin-bottom: ${({ theme }) => theme.space.M};
`

const Wrapper = styled(Box)``

const TagsWrapper = styled(Box)`
  display: flex;
  grid-gap: ${({ theme }) => theme.space.XS};
  flex-wrap: wrap;
`
