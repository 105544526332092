import React, { useCallback, useEffect } from "react"
import styled from "styled-components"

import { Label } from "@motorata/base-ui.components.atoms.typography"
import { IconButton } from "@motorata/base-ui.components.atoms.button"
import { useDispatch, useSelector } from "react-redux"
import { LocalStorageService } from "@motorata/base-ui.services.api-service"
import { logout } from "store/auth"
import Link from "components/atoms/Link/Link"
import LOGO from "assets/images/logo.svg"
import { RootState } from "store/store"

const PanelLayoutNavigation = () => {
  const dispatch = useDispatch()
  const { user } = useSelector((state: RootState) => state.auth)

  const handleLogout = useCallback(() => {
    LocalStorageService.removeObject("token")
    dispatch(logout())
  }, [])

  return (
    <Wrapper>
      <LogoWrapper>
        <img src={LOGO} />
      </LogoWrapper>
      <NavigationWrapper>
        <Link icon="company" to="/panel/profiles" justifyContent="flex-start">
          Profile
        </Link>
        <Border />
        <Link icon="state" to="/panel/tools" justifyContent="flex-start">
          Narzędzia
        </Link>
      </NavigationWrapper>
      <ProfileWrapper>
        <ProfileContent>
          <Label color="white" margin="0" marginLeft="S">
            {`${user?.username}`}
          </Label>
        </ProfileContent>
        <IconButton icon="logout" background="none" color="white" onClick={handleLogout} />
      </ProfileWrapper>
    </Wrapper>
  )
}

export default PanelLayoutNavigation

const HelperWrapper = styled.div`
  padding: ${({ theme }) => theme.space.S};
  background: rgba(255, 255, 255, 0.2);
  border-radius: ${({ theme }) => theme.variable.borderRadius};
  margin-bottom: ${({ theme }) => theme.space.S};
  display: flex;
`

const NavigationWrapper = styled.div`
  flex: 1 1;
  display: flex;
  flex-direction: column;
  grid-gap: ${({ theme }) => theme.space.XXS};
`

const ProfileName = styled(Label)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const ProfileImageWrapper = styled.div`
  height: 50px;
  width: 50px;
  min-width: 50px;
  background: ${({ theme }) => theme.color.background};
  border-radius: ${({ theme }) => theme.variable.borderRadius};
  padding: 5px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`

const ProfileContent = styled.div`
  display: flex;
  align-items: center;
  flex: 1 1;
`

const Border = styled.div`
  width: 100%;
  height: 1px;
  background: rgba(255, 255, 255, 0.2);
`

const ProfileWrapper = styled.div`
  display: flex;
  align-items: center;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  padding: ${({ theme }) => theme.space.S} 0;

  svg {
    color: white !important;
  }
`

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.space.M} 0;

  img {
    width: 80%;
    max-height: 100px;
  }
`

const Wrapper = styled.div`
  width: 100%;
  max-width: 300px;
  height: 100%;
  background: ${({ theme }) => theme.color.primary};
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  padding: 0 ${({ theme }) => theme.space.S};
`
