import React, { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import Alert from "@motorata/base-ui.components.molecules.alert";
import Input from "@motorata/base-ui.components.atoms.input";
import Button from "@motorata/base-ui.components.atoms.button";
import Accordion from "@motorata/base-ui.components.molecules.accordion";
import { ProfileService } from "services/ProfileService";

const ToolPasswordReset = () => {
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        reset,
        unregister,
        formState: { errors, isValid },
    } = useForm()

    const [response, setResponse] = useState("")
    const [loading, setLoading] = useState(false)

    const onSubmit = useCallback(async (el: any) => {
        setLoading(true)
        try {
            await ProfileService.resetPassword(el.username, el.password)
            setResponse("success")
        } catch (e) {
            console.log("[ERROR ProfileService.resetPassword]:", e)
            setResponse("error")
            return
        } finally {
            setLoading(false)
        }
    }, [])

    return (
        <Accordion title="Resetuj hasło do konta">
            {response === "error" && (
                <Alert type="error" small marginBottom="S">
                    Nie udało się ustawić hasła!
                </Alert>
            )}
            {response === "success" && (
                <Alert type="success" small marginBottom="S">
                    Hasło zostało pomyślnie ustawione!
                </Alert>
            )}
            <Input
                name="username"
                label="Username*"
                error={errors.username}
                rhf={register("username", {
                    required: true,
                })}
                marginBottom="S"
            />
            <Input
                name="password"
                label="Hasło*"
                error={errors.password}
                rhf={register("password", {
                    required: true,
                })}
                marginBottom="S"
            />
            <Button marginLeft="auto" icon="send" loading={loading} onClick={handleSubmit(onSubmit)}>
                Zapisz
            </Button>
        </Accordion>
    )
}

export default ToolPasswordReset
