import React, { useEffect } from "react"
import "./App.css"
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom"
import { useSelector } from "react-redux"
import styled from "styled-components"
import Spinner from "@motorata/base-ui.components.atoms.spinner"
import { LocalStorageService } from "@motorata/base-ui.services.api-service"
import { getQuery } from "utils/queryHelper"
import Login from "pages/Login/Login"
import { RootState } from "store/store"
import PanelLayout from "components/layout/PanelLayout";
import Profiles from "pages/Panel/Profiles";
import Tools from "pages/Panel/Tools";
import ProfileModal from "components/modals/ProfileModal";

function Home() {
  const query = getQuery()
  const { isVerifying, isAuthenticated, user } = useSelector((state: RootState) => state.auth)

  useEffect(() => {
    if (query.get("code")) {
      LocalStorageService.setObject("code", `${query.get("code")}`)
    }
  }, [])

  if (isVerifying) {
    return (
      <InitWrapper>
        {/*<Spinner />*/}
      </InitWrapper>
    )
  }

  return (
    <InitWrapper>
      <BrowserRouter>
        <Routes>
          {!isAuthenticated && !user && (
            <>
              <Route path="/login" element={<Login />} />
              <Route path="*" element={<Navigate to="/login" />} />
            </>
          )}
          {isAuthenticated && user && (
            <>
              <Route path="panel" element={<PanelLayout />}>
                <Route path="profiles" element={<Profiles />}>
                  <Route path=":profileId" element={<ProfileModal />} />
                </Route>
                <Route path="tools" element={<Tools />} />
              </Route>
              <Route path="*" element={<Navigate to="/panel/profiles" />} />
            </>
          )}
        </Routes>
      </BrowserRouter>
    </InitWrapper>
  )
}

export default Home

const InitWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.8);
`
