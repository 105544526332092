import React, { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import Alert from "@motorata/base-ui.components.molecules.alert";
import Input from "@motorata/base-ui.components.atoms.input";
import Button from "@motorata/base-ui.components.atoms.button";
import { Box } from "@motorata/base-ui.components.atoms._atoms";
import { OfferService } from "services/OfferService";
import Accordion from "@motorata/base-ui.components.molecules.accordion";

const ToolDeleteOffer = () => {
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        reset,
        unregister,
        formState: { errors, isValid },
    } = useForm()

    const [response, setResponse] = useState("")
    const [loading, setLoading] = useState(false)

    const onSubmit = useCallback(async (el: any) => {
        setLoading(true)
        try {
            await OfferService.deleteOffer(el.id)
            setResponse("success")
        } catch (e) {
            console.log("[ERROR OfferService.deleteOffer]:", e)
            setResponse("error")
            return
        } finally {
            setLoading(false)
        }
    }, [])

    return (
        <Accordion title="Resetuj hasło do konta">
            {response === "error" && (
                <Alert type="error" small marginBottom="S">
                    Nie udało się usunąć ogłoszenia.
                </Alert>
            )}
            {response === "success" && (
                <Alert type="success" small marginBottom="S">
                    Ogłoszenie zostało usunięte pomyślnie!
                </Alert>
            )}
            <Input
                name="id"
                label="ID*"
                error={errors.id}
                rhf={register("id", {
                    required: true,
                })}
                marginBottom="S"
            />
            <Button marginLeft="auto" icon="send" loading={loading} onClick={handleSubmit(onSubmit)}>
                Usuń
            </Button>
        </Accordion>
    )
}

export default ToolDeleteOffer
