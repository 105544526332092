import React from "react";
import styled, { css } from "styled-components"
import Tag from "@motorata/base-ui.components.atoms.tag";
import { translate } from "@motorata/base-ui.data";
import { BaseStyles } from "@motorata/base-ui.types.base-styles";

const Status = ({ label, ...rest }: BaseStyles & {
    label: string
}) => {
    return (
        <StyledStatus
            {...rest}
            type="secondary"
            label={translate(label)}
            status={label}
            showBorder={false}
        />
    )
}

export default Status

const StyledStatus = styled(Tag)<{
    status: string
}>`
  height: max-content;
  padding: ${({ theme }) => theme.space.XXS} ${({ theme }) => theme.space.XS};
  border-radius: 50px;

  ${({ status }) =>
    (status === "active" || status === "paid") &&
    css`
      background: ${({ theme }) => theme.color.greenBackground};

      label {
        color: ${({ theme }) => theme.color.green};
      }
    `};

  ${({ status }) =>
    (status === "add_offer" || status === "pending" || status === "unpaid") &&
    css`
      background: ${({ theme }) => theme.color.orangeBackground};

      label {
        color: ${({ theme }) => theme.color.orange};
      }
    `};

  ${({ status }) =>
    status === "deactivated" &&
    css`
      background: ${({ theme }) => theme.color.redBackground};

      label {
        color: ${({ theme }) => theme.color.red};
      }
    `};
`
