import React, { useCallback, useEffect, useRef, useState } from "react"
import { Box, Row } from "@motorata/base-ui.components.atoms._atoms"
import { useForm } from "react-hook-form"
import styled from "styled-components"
import { Outlet } from "react-router-dom"
import { ProfileType } from "@motorata/base-ui.types.api"
import ProfilesList from "views/Profiles/ProfilesList"
import PanelLayoutContent from "components/layout/PanelLayoutContent"
import { ProfileService } from "services/ProfileService"
import Button from "@motorata/base-ui.components.atoms.button"
import Modal from "@motorata/base-ui.components.molecules.modal"
import Input from "@motorata/base-ui.components.atoms.input"
import CheckboxesGroup from "@motorata/base-ui.components.atoms.checkboxes-group"
import AddProfileModal from "components/modals/AddProfileModal"

const Profiles = () => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    unregister,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      q: "",
    },
  })

  const q = watch("q")

  const firstRender = useRef<boolean>(true)
  const timeoutId = useRef<number | null>()
  const [addCompany, setAddCompany] = useState(false)

  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [data, setData] = useState<ProfileType[]>([])

  const [showFiltersModal, setShowFiltersModal] = useState(false)
  const [filters, setFilters] = useState({
    q: "",
    integration: "",
  })

  const fetchProfiles = useCallback(
    async (newPage: number) => {
      try {
        setLoading(true)
        const { content, totalPages } = await ProfileService.getProfiles(q, newPage, 20)
        setData(content)
        setTotalPages(totalPages)
      } catch (e) {
        console.error("[ERROR AdminProfileService.getProfiles]:", e)
      } finally {
        setLoading(false)
      }
    },
    [q],
  )

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false
      setPage(0)
      fetchProfiles(0)
      return
    }

    if (timeoutId.current) {
      clearTimeout(timeoutId.current)
    }

    // @ts-ignore
    timeoutId.current = setTimeout(() => {
      setPage(0)
      fetchProfiles(0)
    }, 1000)
  }, [q])

  const onChangePage = (newPage: number) => {
    setPage(newPage)
    fetchProfiles(newPage)
  }

  const refresh = () => {
    fetchProfiles(0)
  }

  return (
    <>
      <PanelLayoutContent
        title="Lista profili"
        text="Lista aktualnych profili na platformie"
        button={{
          icon: "plus",
          title: "Dodaj profil",
          onClick: () => setAddCompany(true),
        }}
      >
        <Row justifyContent="flex-end" marginBottom="S">
          <Input name="q" label="Szukaj" rhf={register("q")} width="350px" />
          <FiltersWrapper flex={1}></FiltersWrapper>
          {/*<Button type="secondary" icon="filters" marginLeft="XS" onClick={() => setShowFiltersModal(true)}>Filtruj</Button>*/}
        </Row>
        <ProfilesList
          loading={loading}
          data={data}
          page={page}
          totalPages={totalPages}
          onChangePage={onChangePage}
        />
      </PanelLayoutContent>
      <Modal
        type="side"
        show={showFiltersModal}
        setShow={setShowFiltersModal}
        title="Filtruj listę profili"
      >
        <Input name="q" label="Szukaj" rhf={register("q")} />
        <CheckboxesGroup
          name="states"
          label="Integracje"
          value={[filters.integration]}
          onChange={({ value }) =>
            setFilters({
              ...filters,
              integration: value,
            })
          }
          options={[["Otomoto", "otomoto"]]}
          marginBottom="S"
        />
      </Modal>
      <AddProfileModal show={addCompany} setShow={setAddCompany} onSubmit={refresh} />
      <Outlet />
    </>
  )
}

export default Profiles

const FiltersWrapper = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  grid-gap: ${({ theme }) => theme.space.S};
`
