import { configureStore } from "@reduxjs/toolkit"
import {
  LocalStorageService,
  PublicService,
} from "@motorata/base-ui.services.api-service"
import auth, { errorVerify, initVerify, successVerify } from "./auth"
import { getQuery } from "utils/queryHelper";

export const store = configureStore({
  reducer: {
    auth: auth
  },
})

const { dispatch } = store

const verifyUser = async () => {
  dispatch(initVerify())

  try {
    const user = await PublicService.verify()
    dispatch(successVerify(user))
  } catch (e) {
    console.log("[ERROR PublicService.verify]:", e)
    LocalStorageService.removeObject("token")
    LocalStorageService.removeObject("profileId")
    dispatch(errorVerify())
    return
  }
}

verifyUser()

export type RootState = ReturnType<typeof store.getState>
