import React from "react"
import styled from "styled-components"
import { Box, Row } from "@motorata/base-ui.components.atoms._atoms"
import { LabelH2 } from "@motorata/base-ui.components.atoms.typography"
import { ProfileType } from "@motorata/base-ui.types.api"
import Tag from "@motorata/base-ui.components.atoms.tag"
import { IconButton } from "@motorata/base-ui.components.atoms.button"
import { useNavigate } from "react-router-dom"
import Status from "components/atoms/Status/Status"
import { formatDate } from "utils/date"

const ProfileBlock = ({ data }: { data: ProfileType }) => {
  const navigate = useNavigate()

  return (
    <Wrapper>
      <Content>
        <Box>
          <HeaderWrapper>
            <Box flex={1}>
              <Row justifyContent="space-between">
                <Row flex={1} alignItems="center">
                  <Status label={data.status} />
                  <LabelH2 marginBottom="0" marginLeft="S">
                    {data.name}
                  </LabelH2>
                </Row>
                <IconButton icon="pen" background="none" onClick={() => navigate(data.id)} />
              </Row>
            </Box>
          </HeaderWrapper>
          <TagsWrapper>
            {data.email && (
              <Tag
                type="secondary"
                showBorder={false}
                background="background"
                icon="email"
                iconColor="primary"
                label={data.email}
              />
            )}
            {data.phone && (
              <Tag
                type="secondary"
                showBorder={false}
                background="background"
                icon="phone"
                iconColor="primary"
                label={data.phone}
              />
            )}
            {data.dtCreated && (
              <Tag
                type="secondary"
                showBorder={false}
                background="background"
                icon="clock-check"
                iconColor="primary"
                label={formatDate(data.dtCreated)}
              />
            )}
            {data.integrations.otomoto?.active && (
                <Tag
                    type="secondary"
                    showBorder={false}
                    background="greenBackground"
                    icon="check"
                    iconColor="green"
                    label="Otomoto"
                />
            )}
          </TagsWrapper>
        </Box>
      </Content>
    </Wrapper>
  )
}

export default ProfileBlock

const TagsWrapper = styled(Box)`
  display: flex;
  grid-gap: ${({ theme }) => theme.space.XS};
  flex-wrap: wrap;
`

const HeaderWrapper = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  grid-gap: ${({ theme }) => theme.space.XS};
  margin-bottom: ${({ theme }) => theme.space.XS};
`

const Content = styled(Box)``

const Wrapper = styled(Box)`
  padding: ${({ theme }) => theme.space.XS};
  border-radius: ${({ theme }) => theme.variable.borderRadius};
  border: 1px solid ${({ theme }) => theme.color.fourth};
  background: white;
`
