import React, { useCallback, useEffect, useRef, useState } from "react"
import { Box } from "@motorata/base-ui.components.atoms._atoms"
import Modal from "@motorata/base-ui.components.molecules.modal"
import styled from "styled-components"
import { Label } from "@motorata/base-ui.components.atoms.typography"
import Input from "@motorata/base-ui.components.atoms.input"
import { useForm } from "react-hook-form"
import Alert from "@motorata/base-ui.components.molecules.alert"
import Select from "@motorata/base-ui.components.atoms.select"
import { PublicService } from "@motorata/base-ui.services.api-service"
import { CoordinatorType } from "@motorata/base-ui.types.api"
import Button from "@motorata/base-ui.components.atoms.button";
import { ProfileService } from "services/ProfileService";

const provinces = [
  "Dolnośląskie",
  "Kujawsko-pomorskie",
  "Lubelskie",
  "Lubuskie",
  "Łódzkie",
  "Małopolskie",
  "Mazowieckie",
  "Opolskie",
  "Podkarpackie",
  "Podlaskie",
  "Pomorskie",
  "Śląskie",
  "Świętokrzyskie",
  "Warmińsko-mazurskie",
  "Wielkopolskie",
  "Zachodniopomorskie",
]

const AddProfileModal = ({
  show,
  setShow,
  onSubmit : onSuccessSubmit,
}: {
  show: boolean
  setShow: (e: boolean) => void
  onSubmit: () => void
}) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    unregister,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      email: "",
      name: "",
      profileEmail: "",
      phone: "",
      coordinator: "",
      company: {
        nip: "",
        name: "",
        address: "",
        postCode: "",
        city: "",
        province: "",
      },
      address: {
        address: "",
        postCode: "",
        city: "",
        province: "",
      },
    },
  })

  const timeoutId = useRef()
  const [coordinators, setCoordinators] = useState<CoordinatorType[]>([])

  const [response, setResponse] = useState("")
  const [loading, setLoading] = useState(false)
  const [loadingNip, setLoadingNip] = useState(false)

  const nip = watch("company.nip")

  const getCoordinators = useCallback(async () => {
    try {
      const coordinators = await PublicService.getCoordinators()
      setCoordinators(coordinators)
    } catch (e) {
      console.log("[ERROR getCoordinators API]", e)
    }
  }, [])

  const searchCompanyByNip = useCallback(async (number: string) => {
    if (!number) {
      return
    }

    try {
      setLoadingNip(true)
      const res: any = await PublicService.getCompanyFromGUS(number)
      setValue("company", {
        nip: number,
        name: res.name || "",
        address: res.address,
        city: res.city,
        province: res.province,
        postCode: res.postCode,
      })
      setValue("address", {
        address: res.address || "",
        city: res.city,
        province: res.province,
        postCode: res.postCode,
      })
    } catch (e) {
      console.log("[ERROR getCompanyFromGUS API]", e)
    } finally {
      setLoadingNip(false)
    }
  }, [])

  useEffect(() => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current)
    }

    // @ts-ignore
    timeoutId.current = setTimeout(() => {
      if (nip) {
        searchCompanyByNip(nip)
      }
    }, 1000)
  }, [nip])

  useEffect(() => {
    getCoordinators()
  }, [])

  const onSubmit = useCallback(async (state: any) => {
    setLoading(true)
    try {
      const profile = await ProfileService.registerProfile(state.email, state.coordinator, {
        name: state.name,
        company: state.company,
        address: state.address,
        email: state.profileEmail,
        phone: state.phone,
        type: 'partner',
        params: {}
      })
      setResponse('success')
      setShow(false)
      onSuccessSubmit()
      reset()
    } catch (e) {
      setResponse('error')
      console.log("[ERROR ProfileService.registerProfile]:", e)
    } finally {
      setLoading(false)

    }
  }, [])

  return (
    <Modal show={show} setShow={setShow} type="side" title="Utwórz nowy profil" maxWidth="700px">
      <Wrapper>
        {response === "error" && (
          <Alert type="error" small marginBottom="S">
            Nie udało się utworzyć profilu!
          </Alert>
        )}
        <InfoWrapper>
          <Label display="block" marginBottom="XS">
            Dane logowania
          </Label>
          <Input
            name="email"
            label="E-mail*"
            error={errors.email}
            rhf={register("email", {
              required: true,
            })}
          />
        </InfoWrapper>
        <InfoWrapper>
          <Label display="block" marginBottom="XS">
            Dane firmy
          </Label>
          <InfoContent>
            <Input
              name="nip"
              label="NIP*"
              loading={loadingNip}
              error={errors.company?.nip}
              rhf={register("company.nip", {
                required: true,
              })}
            />
            <Input
              name="name"
              label="Nazwa firmy*"
              error={errors.company?.name}
              rhf={register("company.name", {
                required: true,
              })}
            />
            <Input
              name="address"
              label="Adres*"
              error={errors.company?.address}
              rhf={register("company.address", {
                required: true,
              })}
            />
            <Input
              name="city"
              label="Miasto*"
              error={errors.company?.city}
              rhf={register("company.city", {
                required: true,
              })}
            />
            <Input
              name="postCode"
              label="Kod pocztowy*"
              error={errors.company?.postCode}
              rhf={register("company.postCode", {
                required: true,
              })}
            />
            <Select
              name="province"
              label="Województwo*"
              options={provinces}
              error={errors.company?.province}
              rhf={register("company.province", {
                required: true,
              })}
            />
          </InfoContent>
        </InfoWrapper>
        <InfoWrapper>
          <Label display="block" marginBottom="XS">
            Lokalizacja salonu
          </Label>
          <InfoContent>
            <Input
              name="address"
              label="Adres*"
              error={errors.address?.address}
              rhf={register("address.address", {
                required: true,
              })}
            />
            <Input
              name="city"
              label="Miasto*"
              error={errors.address?.city}
              rhf={register("address.city", {
                required: true,
              })}
            />
            <Input
              name="postCode"
              label="Kod pocztowy*"
              error={errors.address?.postCode}
              rhf={register("address.postCode", {
                required: true,
              })}
            />
            <Select
              name="province"
              label="Województwo*"
              options={provinces}
              error={errors.address?.province}
              rhf={register("address.province", {
                required: true,
              })}
            />
          </InfoContent>
        </InfoWrapper>
        <InfoWrapper>
          <Label display="block" marginBottom="XS">
            Dane profilu
          </Label>
          <Input
            name="name"
            label="Nazwa profilu*"
            error={errors.name}
            rhf={register("name", {
              required: true,
            })}
            marginBottom="S"
          />
          <InfoContent>
            <Input
              name="profileEmail"
              label="E-mail*"
              error={errors.profileEmail}
              rhf={register("profileEmail", {
                required: true,
              })}
            />
            <Input
              name="phone"
              label="Telefon*"
              error={errors.phone}
              rhf={register("phone", {
                required: true,
              })}
            />
          </InfoContent>
        </InfoWrapper>
        <InfoWrapper>
          <Label display="block" marginBottom="XS">
            Opiekun
          </Label>
          <Select
              name="coordinator"
              label="Imię i Nazwisko"
              options={coordinators.map(el => [el.name, el.id])}
              error={errors.coordinator}
              rhf={register("coordinator")}
          />
        </InfoWrapper>
        <Button loading={loading} width="100%" icon="save" onClick={handleSubmit(onSubmit)}>
          Zapisz
        </Button>
      </Wrapper>
    </Modal>
  )
}

export default AddProfileModal

const InfoContent = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${({ theme }) => theme.space.XS};
`

const InfoWrapper = styled(Box)`
  margin-bottom: ${({ theme }) => theme.space.M};
`

const Wrapper = styled(Box)``
