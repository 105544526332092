import { APIAuthService } from "@motorata/base-ui.services.api-service"
import { ProfilesType, ProfileType } from "@motorata/base-ui.types.api"

const getProfile = (id: string) => APIAuthService.get<null, ProfileType>(`/admin/profiles/${id}`)
const getProfiles = (q: string, page: number, size: number) =>
    APIAuthService.get<null, ProfilesType>(`/admin/profiles?q=${q}&page=${page}&size=${size}`)

const registerProfile = (username: string, coordinatorId: string, profile: object) => APIAuthService.post(`/admin/profile/register?username=${username}&coordinatorId=${coordinatorId}`, profile)
const resetPassword = (username: string, password: string) => APIAuthService.post(`/admin/profile/reset-password?username=${username}&password=${password}`)

export const ProfileService = {
  getProfiles,
  getProfile,
  resetPassword,
  registerProfile,
}
